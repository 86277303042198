import _ from 'lodash';
// import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
// import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl, postUrl } from '@utils/ApiAction';

// import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {
    Box, Grid, Typography, Select, ThemeProvider, Pagination, Stack, createTheme, MenuItem, FormControl, Button, CircularProgress, Dialog, DialogTitle, IconButton, DialogContent, TextField, InputAdornment, useMediaQuery
} from '@mui/material';
import { storePaths, tradingAmountFormat } from '@utils/Tools';

import { makeStyles, useTheme, styled } from '@mui/styles';
import { IoWarningOutline } from "react-icons/io5";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ChartData from "@components/ChartData";
import AllButton from '@components/AllButton';
import VscSquareButton from '@components/VscSquareButton';
import DateDisplay from '@components/DateDisplay';
import BTTransactionTable from '@components/TransactionItems/BTTransactionTable';

import { SellOutlined, ShoppingBagOutlined, ChevronRight, Close } from '@mui/icons-material';

const PercentCircular = memo((props) => {
    const theme = useTheme();
    const { value, max, color, barColor } = props;
    const normalizedMax = max !== null && max !== 0 ? max : 1;
    const percentage = isNaN(value) ? 0 : (value / normalizedMax) * 100;

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                background: 'transparent',
                borderRadius: '50%',
                overflow: 'hidden',
            }}
        >
            <CircularProgress
                variant='determinate'
                value={100}
                size={50}
                thickness={5}
                sx={{ color: barColor || '#00000033' }}
            />
            <CircularProgress
                variant='determinate'
                value={percentage}
                size={50}
                thickness={5}
                sx={{ color: color, position: 'absolute', left: 0, strokeLinecap: 'round' }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color={color}>{`${Math.round(percentage)}%`}</Typography>
            </Box>
        </Box>
    );
})

export default function TradeTransactionHistory() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    let location = useLocation();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [selectedStatus, setSelectedStatus] = useState('all');
    const [onGoingData, setOnGoingData] = useState(null);
    const [wallets, setWallets] = useState({});

    const [data, setData] = useState([]);
    const [sumSellUnit, setSumSellUnit] = useState(0);
    const [sumSoldUnit, setSumSoldUnit] = useState(0);
    const [currPage, setCurrPage] = useState(1);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [state, setState] = useState({
        securityPassword: null,
        showPassword: false,
        needStop: false,
        doneStopTrading: false,
    })
    const [inputErrors, setInputErrors] = useState({});
    const [setting, setSetting] = useState({});

    const [chartDatas, setChartData] = useState([]);
    const [statusListing, setStatusListing] = useState([]);
    const [filters, setFilters] = useState([]);

    let navigate = useNavigate();

    // Date
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchDate, setSearchDate] = useState(false);

    //Dialog
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        info: null,
    })

    const goToLink = (link, selectedItem) => {
        navigate(link, { state: { from: storePaths(location), selectedItem } });
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    useEffect(() => {
        debouncedCallApi();

        // eslint-disable-next-line
    }, [page, selectedStatus, endDate]);

    const debouncedCallApi = _.debounce(() => {
        callApi();
    }, 1000);

    const callApi = () => {
        setLoading(true);
        try {
            let apiData = {
                status: selectedStatus,
                per_page: 10,
                page: page,
                start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
                end_date: endDate ? endDate.format('YYYY-MM-DD') : null
            };

            getUrl(`/member-get-sell-histories`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    let { data, current_page, last_page } = response.listing;
                    if (_.size(data) > 0)
                    setCurrPage(current_page);
                    setTotal(last_page);
                    setSumSellUnit(response.data.sum_sell_unit);
                    setSumSoldUnit(response.data.sum_sold_unit);
                    setStatusListing(response.status_listing);
                    setOnGoingData(response.on_going);
                    setData(data);
                    setWallets(response.wallet);
                    setSetting({
                        walletIds: response?.wallet_ids,
                        walletName: response?.wallet_name
                    });

                    let tempFilter = [{ title: t('button.all'), value: 'all' }];
                    _.map(response.status_listing, (data, key) => {
                        if (parseInt(key) !== 10 && parseInt(key) !== 20) {
                            tempFilter = _.concat(tempFilter, { title: data[i18n.language] ? data[i18n.language] : data?.en, value: parseInt(key) });
                        }
                    });
                    setFilters(tempFilter);

                    setChartData([
                        { title: t('trade.sold'), value: response.data.sum_sold_unit },
                        { title: t('trade.selling'), value: response.data.sum_sell_unit },
                    ]);

                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });

        } catch (error) {
            setLoading(false);
            navigate(`/`);
        }
    }

    const handleCustomRangeDate = () => {
        callApi();
    };

    const handleChangeStatusFilter = value => {
        setSelectedStatus(value);
        setPage(1);
        setData([]);
    };

    const closeDialog = () => {
        setState({ ...state, needStop: false, securityPassword: null, showPassword: false });
        setDialogInfo({ ...dialogInfo, info: null, open: false });
    }

    const openDialog = (data) => {
        setState({ ...state, needStop: false, securityPassword: null, showPassword: false });
        setDialogInfo({ ...dialogInfo, info: data, open: true });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;

        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = () => {
        setState({ ...state, showPassword: !state?.showPassword });
    }

    const stopTrading = () => {
        let params = {
            security_password: state?.securityPassword
        }

        postUrl('/member-cancel-sell-trading', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                // closeDialog();
                debouncedCallApi();
                setState(prevState => ({
                    ...prevState,
                    securityPassword: null,
                    doneStopTrading: true,
                }));
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const filteredData = data.filter(item => {
        if (selectedStatus === 'all') {
            return true;
        } else {
            return item.status === parseInt(selectedStatus);
        }
    });

    const calculateTotal = (data) => {
        let total = 0;
        let fee = 0;

        if (data?.sold_unit > 0 || data?.trade?.price > 0) {
            total = data?.sold_unit * data?.trade?.price;
        }

        if (data?.details?.fee_type === 'percent') {
            fee = total * (data?.details?.fee_amount / 100);

        } else if (data?.details?.fee_type === 'fixed') {
            fee = data?.details?.fee_amount;
        }

        total = total - fee;


        return tradingAmountFormat(parseFloat(total).toFixed(3));
    }

    function tradeHistoryListing() {
        if (filteredData.length > 0) {
            return (
                filteredData.map(
                    (item, key) => {

                        return (
                            <div className='p-b-15' key={key} onClick={() => setDialogInfo({ ...dialogInfo, open: true, info: item })}>
                                <Box className='max-w-full w-full p-r-15' underline="none" style={{ cursor: 'pointer' }}>
                                    <BTTransactionTable item={item} needDivider={key === 0 ? false : true} />
                                </Box>
                            </div>
                        );
                    }
                )
            )

        }
        else {
            return (
                <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                    <div className="w-full" style={{ textAlign: 'center' }}>
                        <img src={'/images/general/filter-search-empty-data.png'} width={250} alt="No data" loading='lazy' />
                    </div>
                    <p className='txt-center p-t-20 p-b-30' style={{ fontWeight: 500, color: '#a0a0a0', textTransform: 'uppercase' }}>{t('table.noRecord')}</p>
                </div>
            )
        }

    }

    const statusFilter = () => {
        return (
            <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }} container spacing={2}>
                <Grid item xs={12} sm={7} md={5}>
                    <Typography variant='h6' style={{ fontWeight: 'bold', color: theme.palette.white.main }}>
                        {t('trade.tradingHistory')}
                    </Typography>
                    {mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                </Grid>
                <Grid container item xs={12} sm={5} md={7} className='flex-r-m p-r-20' spacing={3}>
                    {/* DatePicker for start date and end date */}
                    <Grid item sx={{ justifyContent: { xs: 'flex-end' }, display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {!mobileView && <DateDisplay startDate={startDate} endDate={endDate} />}
                            <VscSquareButton startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} searchDate={searchDate} setSearchDate={setSearchDate} />
                        </div>
                    </Grid>
                    <Grid item sx={{ justifyContent: { xs: 'flex-end' }, display: 'flex' }} style={{ paddingLeft: 10 }}>
                        <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const profitLimitChart = () => {
        return (
            <Grid container rowGap={2}
                className='box-out p-lr-20 p-tb-30 flex-c-m h-full gradient-gold-border'
                style={{
                    position: 'relative',
                }}
            >
                <Grid item xs={12} sm={6} className='txt-center'>
                    <ChartData
                        data={chartDatas}
                        // lockView={lockView} 
                        total={(sumSellUnit + sumSoldUnit)}
                        totalTitle={t('trade.tradeTotalUnit')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className='p-lr-40'>
                        <Typography variant='body1' className='p-b-5 txt-upper' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>{t('trade.tradeSummary')}</Typography>
                        <div className='divider-black' style={{ height: 3 }} />
                        <div className='flex-m p-tb-10'>
                            <div
                                // onClick={() => toggleLock(idx)} 
                                className='flex-c-m bor-5 gradient-gold-bg txt-base p-all-10'
                                style={{
                                    // cursor: 'pointer', 
                                    // height: 40,
                                    width: 40,
                                    aspectRatio: 1 / 1,
                                    }}
                            >
                                <SellOutlined className='fs-15'/>
                            </div>
                            <div className='txt-upper p-l-15 fs-15 lh-12 txt-white'>
                                <p style={{ fontSize: 15 }}><b>{sumSellUnit}</b></p>
                                <p style={{ fontSize: 10 }}>{t('trade.selling')}</p>
                            </div>
                        </div>
                        <div className='flex-m p-tb-10'>
                            <div
                                // onClick={() => toggleLock(idx)} 
                                className='flex-c-m bor-5 p-all-8 gradient-gold-border'
                                style={{
                                    // cursor: 'pointer', 
                                    // height: 40,
                                    width: 40,
                                    aspectRatio: 1 / 1,
                                }}
                            >
                                <ShoppingBagOutlined className='fs-15 txt-theme' />
                            </div>
                            <div className='txt-upper p-l-15 fs-15 lh-12 txt-white'>
                                <p style={{ fontSize: 15 }} ><b>{sumSoldUnit}</b></p>
                                <p style={{ fontSize: 10 }}>{t('trade.sold')}</p>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const purchasedSummaryBar = () => {
        let detail = _.clone(onGoingData);

        switch (detail?.status) {
            case 10:
                return (
                    <div className='box-out p-lr-30 p-b-30 flex-c-m h-full gradient-gold-border'>
                        <div className='s-full'>
                            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                <div className={`${classes.smallRectangle} gradient-gold-bg`}>
                                    <p className='txt-upper txt-base'>{t('general.onGoing')}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: 145 }} className='w-full m-t-20'>
                                    <div style={{ flexGrow: 1 }} className='s-full' >
                                        <div className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <div className='flex-c-m'>
                                                    <div style={{ maxWidth: 60, maxHeight: 60 }}>
                                                        <img src={'/images/trade/pending.png'} alt="Pending" loading='lazy' style={{ padding: 5, objectFit: 'cover' }} className="w-full h-full" />
                                                    </div>
                                                    <div>
                                                        <Typography variant="body2">{t('trade.pendingUnit', { value: detail?.trade_unit })}</Typography>
                                                        <Typography variant="caption">{`≈ $ ${tradingAmountFormat(parseFloat( detail?.trade_unit * detail?.trade?.price).toFixed(3))}`}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Typography variant="h6" style={{ textAlign: 'right' }}>{detail?.trade?.price}</Typography>
                                                <Typography variant="caption" className='txt-upper'>{t('trade.sellingRate')}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='s-full'>
                                        <div className='w-full m-t-10' style={{ height: 3, background: theme.palette.primary.main, boxShadow: '0px 1px 2px 0px #00000047 inset, 0px 2px 3px 0px #FFFFFF33' }} />
                                        <div className='w-full m-t-10'>
                                            <div className='flex-sb-m'>
                                                <Typography>{detail?.created_at}</Typography>
                                                <div className="flex-c-m" onClick={() => openDialog(detail)} style={{ cursor: 'pointer' }}>
                                                    <Typography variant="body2" >{t('button.more')}</Typography>
                                                    <ChevronRight style={{ color: 'white' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 20:
                return (
                    <div className='box-out p-lr-30 p-b-30 flex-c-m h-full gradient-gold-border'>
                        <div className='s-full'>
                            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                <div className={`${classes.smallRectangle} gradient-gold-bg`}>
                                    <p className='txt-base txt-upper'>{t('general.onGoing')}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', minHeight: 145 }} className='w-full m-t-20'>
                                    <div style={{ flexGrow: 1 }} className='s-full' >
                                        <div className='flex-sb-m'>
                                            <div className='flex-l-m'>
                                                <div className='flex-c-m'>
                                                    <div style={{ maxWidth: 60, maxHeight: 60 }}>
                                                        <PercentCircular value={detail?.sold_unit} max={detail.trade_unit} color={theme.palette.white.main} />
                                                    </div>
                                                    <div style={{ paddingLeft: 10 }}>
                                                        <Typography variant="body2">{t('trade.sellingUnit', { value: detail?.trade_unit })}</Typography>
                                                        <Typography variant="caption">{`≈ $ ${tradingAmountFormat(parseFloat( detail?.trade_unit * detail?.trade?.price).toFixed(3))}`}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Typography variant="h6" style={{ textAlign: 'right' }}>{detail?.trade?.price}</Typography>
                                                <Typography variant="caption" className='txt-upper'>{t('trade.sellingRate')}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='s-full'>
                                        <div className='w-full m-t-10 divider-list-black'/>
                                        <div className='w-full m-t-10'>
                                            <div className='flex-sb-m'>
                                                <Typography>{detail?.created_at}</Typography>
                                                <div className="flex-c-m" onClick={() => openDialog(detail)} style={{ cursor: 'pointer' }}>
                                                    <Typography variant="body2" >{t('button.more')}</Typography>
                                                    <ChevronRight style={{ color: 'white' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className='box-out p-all-30 flex-c-m h-full bor-15 gradient-gold-border'>
                        <div className='s-full pos-relative'>
                            {/* <div className='w-full' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: mobileView?'relative':'absolute' }}>
                                <Typography variant="button" style={{ maxWidth: 60, textAlign: 'right', lineHeight: '1.1rem' }}>{t('title.marketPlace')}</Typography>
                                <Button
                                    variant="contained"
                                    className={classes.squareButton}
                                    onClick={() => goToLink('/market-price')}
                                >
                                    <img src={'/images/trade/trading.png'} alt="Trading" loading='lazy' style={{ padding: 10 }} className="w-full h-full" />
                                </Button>
                            </div> */}
                            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                <div className="w-full" style={{ textAlign: 'center' }}>
                                    <img src={'/images/general/filter-search-empty-data.png'} width={150} alt="No data" loading='lazy' />
                                </div>
                                <Button variant="contained" className='big-button' style={{ textTransform: 'uppercase', width: '40%' }} onClick={() => goToLink('/trade/sell')}>
                                    {t('button.tradeNow')}
                                </Button>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div>
            <CustomDiv className='dashboard-container'>
                <Grid
                    container
                    spacing={3}
                    justify="center"
                    // alignItems="center"
                    alignItems="stretch"
                    style={{
                        width: '100%'
                    }}
                    sx={{ marginTop: { xs: 5, sm: 5, md: 0, lg: 0 } }}
                >
                    <Grid item sm={12} md={12} lg={5} className='w-full'>{loading ? null : profitLimitChart()} </Grid>

                    <Grid item sm={12} md={12} lg={7} className='w-full'>{loading ? null : purchasedSummaryBar()}</Grid>
                </Grid>
                <div className='p-t-50'>
                <div>{statusFilter()}</div>
                <div className='gradient-gold-border bor-15' style={{paddingBottom: 20, marginTop: 15 }}>
                    {loading ? null : tradeHistoryListing()}
                    <ThemeProvider theme={paginationStyle}>
                        <Stack spacing={2} style={{ justifyContent: 'center' }}>
                            {
                                loading ?
                                    null
                                    :
                                    (
                                        <Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />
                                    )
                            }
                        </Stack>
                    </ThemeProvider>
                </div>
                </div>
                {/* Transation Detail */}
                <Dialog
                    open={dialogInfo.open}
                    onClose={closeDialog}
                    fullWidth
                >
                    <DialogTitle className='w-full' >
                        <div className='flex-r-m'>
                            <Button onClick={closeDialog} style={{ minWidth: 26, aspectRatio: 1/1, padding: 5, borderRadius: 100,  }}>
                                <Close/>
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent className='w-full'>
                        <div className='flex-c-m' style={{ height: '100%', width: '100%', position: 'relative' }}>
                            {
                                dialogInfo?.info?.status === 10 &&
                                <>
                                    <div style={{ height: '100%', position: 'absolute', top: 0, width: '50%', backgroundSize: '20%', backgroundPosition: 'left', backgroundRepeat: 'no-repeat', left: 0, backgroundImage: 'url(/images/trade/pending-bg.png)' }}></div>
                                    <div style={{ height: '100%', position: 'absolute', top: 0, width: '50%', backgroundSize: '20%', backgroundPosition: 'top right', backgroundRepeat: 'no-repeat', right: -175, backgroundImage: 'url(/images/trade/pending-bg.png)', transform: 'rotateY(180deg)' }}></div>
                                </>
                            }
                            <div className='p-b-20' style={{ zIndex: 1 }} >
                                <div className='flex-c-m'>
                                    <Typography className='text-gold-short' style={{textTransform: 'uppercase', fontWeight: 'bold' }} variant="body1" >{t('trade.tradingDetails')}</Typography>
                                </div>
                                <div className='flex-c-m'>
                                    <Typography variant="body1" >{`${dialogInfo?.info?.status == 10 ? (statusListing[dialogInfo?.info?.status][i18n.language] ? statusListing[dialogInfo?.info?.status][i18n.language] : statusListing[dialogInfo?.info?.status]['en']) : ''} ${t('trade.tradeUnit')} `}</Typography>
                                </div>
                                <div className='flex-c-m'>
                                    <Typography className='text-gold-short' style={{ fontWeight: 'bold' }} variant="h6" >{dialogInfo?.info?.trade_unit}</Typography>
                                </div>
                                <div className='flex-c-m'>
                                    <Typography variant="subtitle1" >{`≈ $ ${tradingAmountFormat(parseFloat(dialogInfo?.info?.trade_unit * dialogInfo?.info?.trade?.price).toFixed(3)) || 0} `}</Typography>
                                </div>
                            </div>
                        </div>
                        <div className='bor-20 p-all-5 m-b-20 gradient-gold-border'>
                            <div className='flex-sb-m p-all-10'>
                                <Typography style={{fontWeight: 'bold' }}>{t('trade.dateTime')}</Typography>
                                <Typography>{dialogInfo?.info?.created_at}</Typography>
                            </div>
                            <div className='flex-sb-m p-all-10'>
                                <Typography style={{fontWeight: 'bold' }}>{t('trade.tradingId')}</Typography>
                                <Typography>{dialogInfo?.info?.id}</Typography>
                            </div>
                        </div>
                        <div className='bor-20 p-all-5 m-b-20 gradient-gold-border'>
                            <div className='flex-l-m p-all-10'>
                                <Typography style={{fontWeight: 'bold' }}>{t('trade.tradeSummary')}</Typography>
                            </div>
                            <div className='flex-sb-m p-all-10'>
                                <Typography>{t('trade.sellingRate')}</Typography>
                                <Typography>{dialogInfo?.info?.trade?.price}</Typography>
                            </div>
                            {
                                _.includes([10, 20], dialogInfo?.info?.status) ?
                                    <>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography>{t('trade.totalSoldUnit')}</Typography>
                                            <Typography>{dialogInfo?.info?.sold_unit}</Typography>
                                        </div>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography>{t('trade.balanceUnit')}</Typography>
                                            <Typography>{dialogInfo?.info?.trade_unit - dialogInfo?.info?.sold_unit}</Typography>
                                        </div>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography className='text-gold-short' style={{fontWeight: 'bolder' }}>{t('general.totalUnit')}</Typography>
                                            <Typography className='text-gold-short' style={{fontWeight: 'bolder' }}>{dialogInfo?.info?.trade_unit}</Typography>
                                        </div>
                                    </> :
                                    <>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography>{t('trade.totalSelling')}</Typography>
                                            <Typography>{`$ ${tradingAmountFormat(parseFloat(dialogInfo?.info?.sold_unit * dialogInfo?.info?.trade?.price).toFixed(3)) || 0}`}</Typography>
                                        </div>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography>{`${t('trade.serviceCharge')} ${dialogInfo?.info?.details?.fee_type === 'percent' ? `${dialogInfo?.info?.details?.fee_amount} %` : ''}`}</Typography>
                                            <Typography>{` - $ ${(dialogInfo?.info?.details?.fee_type === 'percent' ? `${tradingAmountFormat(parseFloat((dialogInfo?.info?.sold_unit * dialogInfo?.info?.trade?.price) * (dialogInfo?.info?.details?.fee_amount / 100)).toFixed(3)) || 0} ` : dialogInfo?.info?.details?.fee_amount) || 0}`}</Typography>
                                        </div>
                                        <div className='flex-sb-m p-all-10'>
                                            <Typography className='text-gold-short' style={{fontWeight: 'bolder' }}>{t('trade.totalReceived')}</Typography>
                                            <Typography className='text-gold-short' style={{fontWeight: 'bolder' }}>{`$ ${calculateTotal(dialogInfo?.info)}`}</Typography>
                                        </div>
                                    </>
                            }
                        </div>
                        {
                            _.includes([10, 20], dialogInfo?.info?.status) ?
                                <>
                                    {
                                        state?.needStop && !state?.doneStopTrading &&
                                        <div className='flex-c-m m-b-20'>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                name='securityPassword'
                                                placeholder={t('title.securityPassword') + '*'}
                                                value={state?.securityPassword || ''}
                                                onChange={handleChange}
                                                type={state?.showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handlePasswordShow}>
                                                                {state?.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true
                                                }}
                                                helperText={inputErrors && inputErrors?.security_password ? inputErrors?.security_password : ''}
                                                error={inputErrors && inputErrors?.security_password ? true : false}
                                                InputLabelProps={{ shrink: true }}
                                                className={classes.textFieldStyle}
                                                style={{
                                                    '& .MuiInputBase': {
                                                        boxShadow: '-3px -3px 4px 0px #FFFFFF inset,2px 2px 3px 0px #0000001A inset'
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        state?.doneStopTrading &&
                                        <div className='flex-c-m'>
                                            <Button variant="contained" onClick={closeDialog} className='big-button' style={{ background: theme.palette.primary.main, textTransform: 'uppercase', fontWeight: 'bolder' }} fullWidth>{t('button.done')}</Button>
                                        </div>
                                    }
                                    {
                                        !state?.needStop && !state?.doneStopTrading &&
                                        <div className='flex-c-m m-t-10'>
                                            <Button variant="contained" onClick={() => setState({ ...state, needStop: true, securityPassword: null })} style={{ background: 'transparent', textTransform: 'uppercase', border: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main, fontWeight: 'bolder' }} fullWidth>{t('button.stopTrading')}</Button>
                                        </div>
                                    }
                                    {
                                        state?.needStop && !state?.doneStopTrading &&
                                        <div className='flex-c-m m-t-10'>
                                            <Button variant="contained" onClick={stopTrading} style={{ background: 'transparent', textTransform: 'uppercase', border: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.main, fontWeight: 'bolder' }} fullWidth>{t('button.stopTrading')}</Button>
                                        </div>
                                    }
                                </> :
                                _.size(dialogInfo?.info?.details?.wallet) > 0 ?
                                    <>
                                        <div style={{ backgroundColor: theme.palette.backgroundColor, boxShadow: '3px 3px 4px 0px #FFFFFF,3px 3px 2px 0px #0000001A inset', borderRadius: 20, padding: 5, marginBottom: 20 }}>
                                            <div className='flex-sb-m p-all-10'>
                                                <Typography style={{ color: '#ADADAD', fontWeight: 'bold' }}>{t('trade.earningBreakdown')}</Typography>
                                                <Typography style={{ color: theme.palette.darkGray.main, fontWeight: 'bold' }}>{calculateTotal(dialogInfo?.info)}</Typography>
                                            </div>
                                            {
                                                _.map(dialogInfo?.info?.details?.wallet, (item, walletId) => {
                                                    let walletItem = _.find(wallets, { 'id': parseInt(walletId) });
                                                    let totalValue = calculateTotal(dialogInfo?.info);
                                                    if (_.size(setting?.walletIds?.wallet_ids) > 0 && _.includes(setting?.walletIds?.wallet_ids, parseInt(walletId))) {
                                                        return (
                                                            <div className='flex-sb-m p-all-10' key={walletId}>
                                                                <Typography style={{ color: theme.palette.darkGray.main }}>{` ${item}% ${setting?.walletName[i18n.language] ? setting?.walletName[i18n.language] : setting?.walletName?.en}`}</Typography>
                                                                <Typography style={{ color: theme.palette.darkGray.main }}>{tradingAmountFormat(parseFloat(totalValue * (item / 100)).toFixed(3))}</Typography>
                                                            </div>
                                                        )
                                                    } else if (walletItem) {
                                                        return (
                                                            <div className='flex-sb-m p-all-10' key={walletId}>
                                                                <Typography style={{ color: theme.palette.darkGray.main }}>{` ${item}% ${walletItem?.name[i18n.language] ? walletItem?.name[i18n.language] : walletItem?.name?.en}`}</Typography>
                                                                <Typography style={{ color: theme.palette.darkGray.main }}>{tradingAmountFormat(parseFloat(totalValue * (item / 100)).toFixed(3))}</Typography>
                                                            </div>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                    : null
                        }
                    </DialogContent>
                </Dialog>
            </CustomDiv>
        </div>
    );
}

const paginationStyle = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                ul: {
                    justifyContent: 'center',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#F0F0F0', // Change pagination numbers color to red
                },
            },
        },
    }
});

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 80,
    },
});

const useStyles = makeStyles(theme => ({
    smallRectangle: {
        // background: '#F1F1F1',
        boxShadow: '-2px -2px 4px 0px #0000001A inset, 4px 0px 4px 0px #00000026 inset',
        padding: 5,
        width: '35%',
        textAlign: 'center',
        clipPath: 'polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%)'
    },
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    squareButton: {
        color: theme.palette.white.main,
        borderRadius: '10px',
        minWidth: '40px',
        height: '40px',
        marginLeft: 10,
        aspectRatio: 1 / 1,
        padding: "0px 0px",
    }
}));
