import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation,} from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes } from 'react-router-dom';

import { Avatar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, IconButton, Drawer, Grid, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { removeLoginAccess, getUrl } from '@utils/ApiAction';
import { changeLanguage, storeMaxRank, storeProfile } from '@actions';
import { storePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { public_restricted_routes, public_routes, private_routes } from '@configs/Router';
import { postUrl } from '@utils/ApiAction';

import { Dashboard, Wallet, Edit, AddBusinessOutlined, QrCodeScanner, AccountTree, AddShoppingCart, History, DonutLarge, Password, Newspaper, Language, MeetingRoom, Money, CurrencyExchange, BusinessOutlined, ReceiptLong, ContactSupport } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { FaLink } from "react-icons/fa";

const MAIN_NAVIGATE = ['toMstore', 'home', 'address', 'funds', 'recruit', 'become-merchant', 'merchant-listing', 'merchant-withdraw', 'merchant-withdraw-history', 'referral-qr', 'community', 'plan-histories', 'passwordHome', '2fa', 'crypto-address', 'notices', 'faq', 'help-center', 'languages', 'logout', 'plans', 'trade', 'bonus', 'transfer', 'deposit', 'withdraw', 'transaction', 'spend-history','merchant-convert','merchant-spending-history','merchant-listing','merchant-withdraw-history','merchant-withdraw', 'convert', 'merchant-withdraw', 'merchant-withdraw-history', 'merchant-listing', 'merchant-convert-ap-to-value', 'merchant-transfer', 'profile'];
const HIDE_BAR = ['/login', '/register', '/forgot-password', '/landing', '/reset-password', '/verifyPage/:id/:hash','/login-redirect','/third-party-checkout/:key'];

const SideBar = () => {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { accessToken} = useSelector(state => state.general);
    const { username, email, profile_image, max_rank, merchant, bind } = useSelector(state => state.user);
    const [langOpen, setLangOpen] = useState(false);
    const { setLoading,addAlert } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [value, setValue] = useState('home');
    const [selectedIcon, setSelectedIcon] = useState('home');
    const [selectedLang, setSelectedLang] = useState('en');
    const hideSidebar = location.state?.hideSidebar || false;
    const [isCrispVisible, setCrispVisible] = useState(false);

    let path = location.pathname.split('/');

    // Emit the 'camera' event when a link is clicked
    window.dispatchEvent(new Event('camera'));

    useEffect(() => {
        let temp_value = _.intersection(MAIN_NAVIGATE, path);
        if (_.size(temp_value) > 0) {
            // console.log(temp_value[0]);
            setValue(temp_value[0]);
            setSelectedIcon(temp_value[0]);
        } else {
            setValue('home');
            setSelectedIcon('home');
        }
    }, [path]);

    useEffect(() => {
        setSelectedLang(i18n.language);
    }, [i18n.language]);

    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;
    let current_path = location?.pathname;
    let checkPath = matchRoutes(private_routes, current_path)?.[0]?.['route'];

    const getRouterFormal = () => {
        const routePath = matchRoutes(public_restricted_routes, current_path)?.[0]?.route?.path || matchRoutes(public_routes, current_path)?.[0]?.route?.path || matchRoutes(private_routes, current_path)?.[0]?.route?.path;
        return routePath || null;
    };

    const logout = () => {
        removeLoginAccess();
        navigate('/login');
    };

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
    };

    const itemDisplay = (bkg, icon, label, action) => {
        let lang = label === t('profile.language') ? true : false;
        return (
            <div className={`pos-relative`}>
                {/* <div style={{ width: 30, height: 30, right: 0, top: -28, display: _.includes(bkg, value) ? 'block' : 'none' }} className='flex-r-m w-full pos-absolute'>
                    <img src='/images/general/subtract.png' alt='Sidebar' className='w-full' />
                </div> */}
                <ListItem
                    style={{ padding: '8px 35px' }}
                    value={value}
                    className={_.includes(bkg, value) ? 'sel-bkg gradient-gold-bg' : 'default-bkg'}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                        setSelectedIcon(newValue);
                    }}>
                    <ListItemButton onClick={action} >
                        <ListItemIcon style={{color: _.includes(bkg, value) ? '#0F0F0F' : '#F0F0F0'}}>
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            primaryTypographyProps={{
                                style: { fontWeight: "bold", fontSize: 16, color: _.includes(bkg, value) ? '#0F0F0F' : '#F0F0F0' }
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </div>
        )
    }

    useEffect(() => {
        if (!window.$crisp) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = "e93083a2-ca89-43c9-a8f8-e8d11c241267";
            (function() {
                const d = document;
                const s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = 1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
        }
    }, []);
    
    useEffect(() => {
        if(window.$crisp){
            if(current_path==='/login'){
                window.$crisp.push(["do", "chat:show"]);
            }
            else{
                if (isCrispVisible) {
                    // loadCrisp();
                    window.$crisp.push(["do", "chat:open"]);
                    window.$crisp.push(["on", "chat:closed", () => {
                        setCrispVisible(false);
                        window.$crisp.push(["do", "chat:hide"]);
                    }]);
                }else{
                    window.$crisp.push(["do", "chat:hide"]);
                }
            }
        }
    }, [isCrispVisible, path]);

    const toggleCrisp = () => {
        setCrispVisible(!isCrispVisible);
        if(window.$crisp){
            if (!isCrispVisible) {
                // loadCrisp();
                window.$crisp.push(["do", "chat:show"]);
                window.$crisp.push(["do", "chat:open"]);
            } else {
                window.$crisp.push(["do", "chat:hide"]);
            }
        }
    };

    return (
        <>
            {
                // !_.includes(HIDE_BAR, location?.pathname)
                !_.includes(HIDE_BAR, getRouterFormal()) && !hideSidebar
                    ?

                    <div className={styles.card} >
                        <div className={`${styles.infoCard}`}>
                            <div style={{ width: 85, margin: '0 auto' }}>
                                <img src="/images/logos/logo-white.png" alt="logo" className='w-full' />
                            </div>
                        </div>
                        <div className='p-b-100' style={{ paddingLeft: 15 }}>
                            {/* ACCOUNT */}
                            <div className={styles.titleText}>
                                {t('profile.account')}
                            </div>
                            <List>

                                {itemDisplay(
                                    ['home'],
                                    <Dashboard />,
                                    t('profile.dashboard'),
                                    () => goToLink("/")
                                )}

                                {itemDisplay(
                                    ['funds', 'bonus', 'transfer', 'deposit', 'withdraw', 'transaction', 'convert'],
                                    <Wallet />,
                                    t('title.wallet'),
                                    () => goToLink("/funds/wallet")
                                )}

                                {itemDisplay(
                                    ['recruit'],
                                    <Edit />,
                                    t('profile.registerAcc'),
                                    () => goToLink("/account/recruit")
                                )}

                                {/* {itemDisplay(
                                    ['referral-qr'],
                                    <QrCodeScanner />,
                                    t('profile.referralQr'),
                                    () => goToLink("/account/referral-qr")
                                )} */}

                                {itemDisplay(
                                    ['community'],
                                    <AccountTree />,
                                    t('title.community'),
                                    () => goToLink("/community/sponsor")
                                )}

                                {itemDisplay(
                                    ['plans'],
                                    <AddShoppingCart />,
                                    t('title.plan'),
                                    () => goToLink("/plans")
                                )}

                                {itemDisplay(
                                    ['plan-histories'],
                                    <History />,
                                    t('title.planHistory'),
                                    () => goToLink("/plan-histories")
                                )}

                                {/* {itemDisplay(
                                    ['spend-history'],
                                    <Money />,
                                    t('title.spendHistory'),
                                    () => goToLink("/spend-history")
                                )} */}

                                {itemDisplay(
                                    ['trade'],
                                    <DonutLarge />,
                                    t('title.trade'),
                                    () => goToLink("/trade")
                                )}

                            </List>
                            {/* Link Acc To Mstore
                            <div className={styles.titleText}>
                                {t('profile.thirdParty')}
                            </div>
                            <List>
                            {
                                bind && bind['Mstore'] ?
                                null
                                :
                                <List>
                                    {itemDisplay(
                                        ['toMstore'],
                                        <FaLink />,
                                        t('profile.toMstore'),
                                        () => goToLink("/linkAcc/toMstore")
                                    )}
                                </List>
                            }
                            {itemDisplay(
                                    ['thirdParty'],
                                    <CurrencyExchange />,
                                    t('title.toThirdParty'),
                                    () => goToLink("/to-thirdparty")
                                )}

                            </List> */}
                            {/* MERCHANT */}
                            {/* <div className={styles.titleText}>
                                {t('profile.merchant')}
                            </div>
                            <List>
                                {
                                    merchant?.status !== 20 &&
                                    itemDisplay(
                                        ['become-merchant'],
                                        <AddBusinessOutlined />,
                                        t('merchant.becomeMerchant'),
                                        () => goToLink("/become-merchant")
                                    )
                                }
                                
                                {
                                    itemDisplay(
                                        ['merchant-listing'],
                                        <BusinessOutlined />,
                                        t('title.merchantListing'),
                                        () => goToLink("/merchant-listing")
                                    )
                                }

                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-withdraw'],
                                        <PaymentsOutlinedIcon />,
                                        t('title.merchatnWithdrawal'),
                                        () => goToLink("/merchant-withdraw")
                                    )
                                }
                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-withdraw-history'],
                                        <ReceiptLong />,
                                        t('title.merchantWithdrawalsHistory'),
                                        () => goToLink("/merchant-withdraw-history")
                                    )
                                }
                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-spending-history'],
                                        <PointOfSaleIcon />,
                                        t('title.merchantReceiveTrade'),
                                        () => goToLink("/merchant-spending-history")
                                    )
                                }
                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-convert-ap-to-value'],
                                        <MoveDownOutlinedIcon />,
                                        t('title.convertAPtoValue'),
                                        () => goToLink("/merchant-convert-ap-to-value")
                                    )
                                }
                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-convert'],
                                        <CurrencyExchange />,
                                        t('profile.convert'),
                                        () => goToLink("merchant-convert")
                                    )
                                }

                                {
                                    parseInt(merchant?.status) === 20 &&
                                    itemDisplay(
                                        ['merchant-transfer'],
                                        <SwitchAccessShortcutAddIcon />,
                                        t('profile.transfer'),
                                        () => goToLink("merchant-transfer")
                                    )
                                }
                               
                            </List> */}

                            <div className={styles.titleText}>
                                {t('profile.security')}
                            </div>
                            <List>
                                {itemDisplay(
                                    ['passwordHome'],
                                    <Password />,
                                    t('profile.password'),
                                    () => goToLink("/account/passwordHome")
                                )}
                                {itemDisplay(
                                    ['2fa'],
                                    <LockIcon />,
                                    t('profile.twoFactorAuth'),
                                    () => goToLink("/account/2fa")
                                )}
                                {/* {itemDisplay(<FaWallet className="img-style" />, t('profile.bankAcc'), null)} */}
                                {itemDisplay(
                                    ['crypto-address'],
                                    <img
                                        className="icon-img-style"
                                        src={`/images/profile_icon/crypto.png`}
                                        alt="Crypto Address Icon"
                                        loading='lazy'
                                    />,
                                    t('title.cryptoAddress'),
                                    () => goToLink("/account/crypto-address")
                                )}
                            </List>
                            {/* <div className={styles.divider} /> */}
                            {/* SETTING */}
                            <div className={styles.titleText}>
                                {t('profile.setting')}
                            </div>
                            <List>
                                {itemDisplay(
                                    ['profile'],
                                    <AccountBoxIcon />,
                                    t('title.profile'),
                                    () => goToLink("/profile")
                                )}
                                {itemDisplay(
                                    ['notices'],
                                    <Newspaper />,
                                    t('title.newsAnnouncements'),
                                    () => goToLink("/account/notices")
                                )}
                                {itemDisplay(
                                    ['language'],
                                    <Language />,
                                    langOpen ? t('profile.language') : selectedLang === 'en' ? 'Language | 中文' : '语言 | English',
                                    () => {
                                        const newLang = selectedLang === 'en' ? 'cn' : 'en';
                                        changeSystemLanguage(newLang);
                                        setSelectedLang(newLang);
                                    }
                                )}
                                {itemDisplay(
                                    ['logout'],
                                    <ContactSupport />,
                                    t('profile.contactUs'),
                                    toggleCrisp
                                )}
                                {itemDisplay(
                                    ['logout'],
                                    <MeetingRoom />,
                                    t('profile.logout'),
                                    () => logout()
                                )}
                            </List>
                        </div>

                    </div>
                    : null}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        width: '100%',
        height: '100%',
        flexShrink: 0,
        background: theme.palette.primary.main
    },
    avatarOutlineStyle: {
        borderRadius: 48,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        padding: 3,
        width: 75,
        height: 75
    },
    infoText: {
        wordBreak: 'break-all',
        color: '#eee',
        lineHeight: '14.52px',
        margin: '5px 0'
    },
    infoCard: {
        margin: 20,
        padding: '20px 0 15px',
        boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.25) inset',
    },
    titleText: {
        color: '#CFCFCF',
        fontWeight: 'bold',
        // textShadow: '1px 1px 1px #FFF',
        textTransform: 'uppercase',
        marginTop: 20,
        padding: '8px 35px'
    },
    divider: {
        // background: '#F1F1F1',
        // boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF',
        // height: '4px',
        // margin: '5px 0'
        height: 7,
        margin: '5px 0',
        background: '#8e5213',
        boxShadow: '0px 3px 4px 0px rgb(0 0 0 / 32%) inset, 0px 2px 3px 0px #ffffff2e',
        borderRadius: 48
    },
    card: {
        // backgroundImage: `url('../images/profile/account_bg.png')`,
        // backgroundSize: '120px 120px',
        // backgroundRepeat: 'no-repeat',
        // backgroundPositionX: '300px',
        // backgroundPositionY: '85%',
        height: '100vh',
        width: 420,
        overflow: 'hidden',
        overflowY: 'scroll',
        background: '#0F0F0F',
    },
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin: '0 auto',
        borderRadius: '30px 30px 0px 0px'
    }
}));

export default SideBar;