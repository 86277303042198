import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import { Typography, Grid, Box, ThemeProvider, Stack, Pagination, Tab, Tabs, Hidden, createTheme, useMediaQuery, Button } from '@mui/material';
import { makeStyles, useTheme, styled } from '@mui/styles';
import { Lock, LockOpen, Add } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import { storePaths, currencyFormat } from '@utils/Tools';
import PropTypes from 'prop-types';
import useNotificationLoading from '@utils/useNotificationLoading';
import PlanHistoryItem from '@components/PlanHistoryItem';
import ChartData from '@components/ChartData';
import AllButton from '@components/AllButton';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Grid item xs={12} lg={6} className='w-full'
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </Grid>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PlanHistory = () => {
    const { accessToken } = useSelector((state) => state.general);
    const { id } = useSelector((state) => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { t, i18n } = useTranslation();

    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    // const scrollableDivRef = useRef(null);
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const scrollableDivRef = document.getElementById('main');
    let navigate = useNavigate();
    let location = useLocation();

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [planHistories, setPlanHistories] = useState([]);
    const [allPlanHistories, setAllPlanHistories] = useState({});
    const [statusList, setStatusList] = useState({});
    const [chartData, setChartData] = useState([]);
    const [profitLimit, setProfitLimit] = useState(0);
    const [profitLimitBalance, setProfitLimitBalance] = useState(0);
    const [totalProfitLimit, setTotalProfitLimit] = useState(0);
    const [activePlans, setActivePlans] = useState({});
    const [isHovered, setIsHovered] = useState(false);
    const [statusFilter, setStatusFilter] = useState('all');
    // const [lockView, setLockView] = useState([false, false]);
    const [value, setValue] = useState(0);

    const filters = [
        {title: t('button.all'), value: 'all'},
        {title: t('button.completed'), value: 'completed'},
        {title: t('button.pending'), value: 'pending'},
        {title: t('button.cancelled'), value: 'cancelled'},
        {title: t('button.expired'), value: 'expired'},
    ];

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [page, t, statusFilter]);

    useEffect(() => {
        getUrl(`/active-plans`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error, data , limit} = response;

                if (status) {
                    setActivePlans(data);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }, []);

    const handleScroll = () => {
        const scrollTop = scrollableDivRef.scrollTop;
        const scrollHeight = scrollableDivRef.scrollHeight;
        const clientHeight = scrollableDivRef.clientHeight;

        if (Math.ceil(scrollHeight - scrollTop) <= (clientHeight + 10)) {
            setPage(page + 1);
        }
    }

    const callApi = () => {
        setLoading(true);
        isMountedRef.current = true;
        let apiData = {
            status: statusFilter,
            per_page: 10,
            page: page,
        };

        getUrl(`/active-plan-histories/${id}`, apiData).then((response) => {
            setLoading(false);
            if (response && isMountedRef.current) {
                let { data, current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);
                setPlanHistories(data);
                setStatusList(response.data.status_list);

                if (statusFilter === 'all') {
                    setAllPlanHistories(response.data.all_plan_histories);
                }

                // Infinite Scroll
                if (mobileView && scrollableDivRef) {
                    if (scrollableDivRef && page < last_page) {
                        scrollableDivRef.addEventListener('scroll', handleScroll);
                    }
                    if (page > 1) {
                        setPlanHistories(_.concat(planHistories, data));
                    } else {
                        setPlanHistories(data);
                    }
                }

                // Get All Data of Plan Histories (Ignoring the pagination)
                let claim = parseFloat(response.data?.total_profit_limit?.profit_limit) - parseFloat(response.data?.total_profit_limit?.profit_limit_balance);
                setTotalProfitLimit(parseFloat(response.data.total_profit_limit.profit_limit ? response.data.total_profit_limit.profit_limit : 0));
                setProfitLimit(claim);
                setProfitLimitBalance(parseFloat(response.data.total_profit_limit.profit_limit_balance ? response.data.total_profit_limit.profit_limit_balance : 0));
                setChartData([
                    {title: t('planHistory.claim'), value: claim ? claim : 0},
                    {title: t('planHistory.balance'), value: parseFloat(response.data.total_profit_limit.profit_limit_balance ? response.data.total_profit_limit.profit_limit_balance : 0)},
                ]);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        return () => { 
            isMountedRef.current = false; 
            scrollableDivRef.current.removeEventListener('scroll', handleScroll);
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
        // Perform any actions when the mouse enters the div
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // Perform any actions when the mouse leaves the div
    };

    const onChangePage = (page) => {
        setPage(page);
    }

    const handleChangeStatusFilter = value => {
        setStatusFilter(value);
        setPage(1);
        // setPlanHistories({});
    };

    // Function to toggle the value of lockView[key]
    // const toggleLock = (key) => {
    //     setLockView(prevState => ({ ...prevState, [key]: !prevState[key] }));
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const bgColors = [
        '#efd796',
        '#f4d17a',
        '#facb5f',
        '#ffc543',
        '#facb5f',
        '#f4d17a',
    ];

    let count = Array.from({ length: activePlans?.length }, (_, index) => 0);
    _.map(allPlanHistories, (detail) => {
        let search = _.find(activePlans, { id: detail?.plan_id });
        if (search) {
            count[detail?.plan_id - 1] += 1;
        }
    });

    /* Display Chart and Bar */
    const profitLimitChart = () => {
        return (
            <Grid container rowGap={2}
                className='box-out p-lr-20 p-tb-30 flex-c-m h-full'
                style={{
                    position: 'relative',
                }}
            >
                <Grid item xs={12} className='flex-r-m' style={{ position: 'absolute', top: 10, right: 20 }}>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/plans', { state: { from: storePaths(location) } })}
                        className='flex-c-m'
                        style={{
                            width: 40,
                            minWidth: 40,
                            height: 40,
                            aspectRatio: 1 / 1,
                            borderRadius: 10,
                        }}
                    >
                        <Add />
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} className='txt-center'>
                    <ChartData
                        data={chartData}
                        // lockView={lockView} 
                        total={totalProfitLimit}
                        totalTitle={t('planHistory.totalProfitLimit')}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className='p-lr-40'>
                        <Typography variant='body1' className='txt-upper text-gold-short' sx={{ textAlign: { xs: 'center', sm: 'left' } }}>{t('planHistory.profitLimit')}</Typography>
                        {
                            ((profitLimitBalance / totalProfitLimit) * 100) <= 10 &&
                            <Typography sx={{ fontSize: 14, color: theme.palette.red.main, fontWeight: '500', textAlign: { xs: 'center', sm: 'left' } }}>{t('planHistory.lowBalance')}</Typography>
                        }
                        <div className='divider-black' />
                        {
                            _.map(chartData, (item, idx) => (
                                <div key={idx} className='flex-m p-tb-10'>
                                    <div
                                        // onClick={() => toggleLock(idx)} 
                                        className={`flex-c-m ${idx === 0 ? 'gradient-gold-bg' : 'gradient-gold-border'}`}
                                        style={{
                                            // cursor: 'pointer', 
                                            height: 40,
                                            width: 40,
                                            aspectRatio: 1 / 1,
                                            borderRadius: 10,
                                            // boxShadow: lockView[idx] ? '0px 4px 4px 0px #00000091 inset, -2px -2px 4px 0px #00000040 inset' : '0px 4px 4px 0px #FFFFFF91 inset, -2px -2px 4px 0px #00000040 inset'
                                            // boxShadow: '0px 4px 4px 0px #FFFFFF91 inset, -2px -2px 4px 0px #00000040 inset'
                                        }}
                                    >
                                        {idx === 0 ? <LockOpen className="txt-base" /> : <Lock className='txt-theme' />}
                                    </div>
                                    <div className='txt-upper p-l-15 fs-15 lh-12' style={{ color: idx === 0 ? theme.palette.white.main : theme.palette.white.main }}>
                                        <p><b>{currencyFormat((item.value).toFixed(2),2)}</b></p>
                                        <p>{item.title}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }

    const purchasedSummaryBar = () => {
        return (
            <div className='box-out p-all-30 flex-c-m h-full' >
                <div className='s-full'>
                    <Typography variant='h6' className='p-b-15 txt-center text-gold-short'><b>{t('planHistory.totalPackagePurchased', { value: allPlanHistories?.length })}</b></Typography>
                    <div className='flex-m flex-col h-full' style={{overflow:'auto', maxHeight:150}}>
                        {_.map(activePlans, (data, key) => {
                            let color_index = key % bgColors.length;
                            return (
                                <Grid container className='flex-m w-full p-tb-3' key={key} >
                                    <Grid item xs={12} sm={3} className='p-r-15' style={{ color: '#F0F0F0' }}>{data?.name?.[i18n.language]}</Grid>
                                    <Grid item xs={12} sm={9} className='flex-m w-full' style={{ color: bgColors[color_index] }}>
                                        <div className={`m-r-${count[key] === 0 ? 0 : 15}`} style={{ width: allPlanHistories?.length > 0 ? `${((count[key] / allPlanHistories?.length) * 100).toString()}%` : 0, height: 15, background: bgColors[color_index] }} />
                                        <p>{count[key]}</p>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Hidden mdUp>
                <div style={{ display: 'flex', justifyContent: 'center',  }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        style={{
                            borderRadius: 3,
                            boxShadow: '0px 2px 2px 0px #0000001A',
                            paddingTop: 80,
                            width: '100%',
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        }}
                    >
                        <Tab label={t('planHistory.profitLimit')} {...a11yProps(0)}
                            style={{
                                fontSize: 14,
                                background: 'transparent',
                                boxShadow: 'none',
                                color: theme.palette.lightGray.main,
                            }}
                            sx={{
                                '&.Mui-selected': {
                                    color: `${theme.palette.white.main} !important`,
                                },
                            }}
                        />
                        <Tab label={t('planHistory.purchasedSummary')} {...a11yProps(1)}
                            style={{
                                fontSize: 14,
                                background: 'transparent',
                                boxShadow: 'none',
                                color: theme.palette.lightGray.main,
                            }}
                            sx={{
                                '&.Mui-selected': {
                                    color: `${theme.palette.white.main} !important`,
                                },
                            }}
                        />
                    </Tabs>
                </div>
            </Hidden>

            <CustomDiv className='dashboard-container'>
                <Hidden mdUp>
                    <Grid container spacing={3}>
                        <CustomTabPanel value={value} index={0}>
                            <>{profitLimitChart()}</>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            <>{purchasedSummaryBar()}</>
                        </CustomTabPanel>
                    </Grid>
                </Hidden>
                
                <Hidden mdDown>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} className='w-full'>{profitLimitChart()}</Grid>
                        <Grid item xs={12} lg={6} className='w-full'>{purchasedSummaryBar()}</Grid>
                    </Grid>
                </Hidden>

                <div className={styles.walletBalanceLayout}>
                    <p className='text-gold'>
                        {t('planHistory.title')}
                    </p>

                    {/* filter the status */}
                    <AllButton filters={filters} handleChange={handleChangeStatusFilter} />
                </div>

                <Grid container spacing={4} style={{ paddingTop: 20 }}>
                    {
                        planHistories.length > 0 ? (
                            planHistories.map((history, key) => (
                                <Grid item xs={12} lg={6} key={history.id} style={{ cursor: 'pointer' }}>
                                    <Box>
                                        <PlanHistoryItem
                                            history={history}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12} className={styles.endFontStyle} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src="images/general/filter-search-empty-data.png" alt="" style={{ marginBottom: '10px', width: 145 }} loading='lazy' />
                                <p className='txt-empty'>
                                    {t('table.noTransactionYet')}
                                </p>
                            </Grid>
                        )
                    }

                    <Hidden lgDown>
                        {
                            planHistories.length > 0 &&
                            <Grid item xs={12} className='p-t-10'>
                                <ThemeProvider theme={paginationStyle}>
                                    <Stack spacing={2}>
                                        {
                                            loading
                                                ? null
                                                : (<Pagination count={total} page={page} onChange={(event, page) => onChangePage(page)} />)
                                        }
                                    </Stack>
                                </ThemeProvider>
                            </Grid>
                        }
                    </Hidden>

                    {
                        planHistories.length > 0 && mobileView && (
                            <Grid item xs={12} className={styles.endFontStyle}>
                                <Typography style={{ color: '#F0F0F0' }}>
                                    {currPage === total ? t('table.endOfTheList') : t('table.loadingList') }
                                </Typography>
                            </Grid>
                        )
                    }
                </Grid>
            </CustomDiv>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    walletBalanceLayout: {
        display: 'flex',
        alignItems: 'flex-end',
        // backgroundImage: `url('images/wallets/accumulate_background.png')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        width: '100%',
        minHeight: 95,
        justifyContent: 'space-between',
    },
    colorGray: {
        color: theme.palette.lightGray.main
    },
    endFontStyle: {
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 30,
    },
}));

const paginationStyle = createTheme({
    components: {
        // Name of the component
        MuiPagination: {
            styleOverrides: {
                // Name of the slot
                ul: {
                    // Some CSS
                    justifyContent: 'flex-end',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: '#eed189',
                },
            },
        },
    }
});

const CustomDiv = styled('div')({
    paddingTop: 30,
    '@media (min-width: 900px)': {
        paddingTop: 80,
    },
});

export default PlanHistory;