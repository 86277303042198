import _, { isEmpty, isNil } from 'lodash';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Dialog, FormControl, IconButton, InputAdornment, Link, MenuItem, Select, Slide, TextField, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BiSearch } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";
import { FiChevronsDown, FiCornerLeftDown, FiCornerRightDown , FiChevronsUp } from "react-icons/fi";

import { getUrl } from '@utils/ApiAction';
import { storePaths, useQuery , currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import '@css/genealogyPlacement.css';
import theme from '../../Theme';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenealogyPlacement() {
    const { t, i18n } = useTranslation();
    const { id, username } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();

    let location = useLocation();
    const shareLink = useRef(null);
    const navigate = useNavigate();
    const styles = useStyles();
    const urlUsername = useQuery().get('u');
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const xsView = useMediaQuery((theme) => theme.breakpoints.down('400'));

    const [state, setState] = useState({ username: urlUsername ? urlUsername : '' });
    const [openSearch, setOpenSearch] = useState(false);
    const [data, setData] = useState({});
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [showQr, setShowQr] = useState(false);
    const [qrUrl, setQrUrl] = useState("");
    const [qrData, setQrData] = useState({ username: "", position: "" });
    const [recruitUrl, setRecruitUrl] = useState("");
    const [treePosition, setTreePosition] = useState({ 3: 1 }); // default show left
    const [farLeftRight, setFarLeftRight] = useState({}); // default show left
    const [checkError, setCheckError] = useState(false);

    useEffect(() => {
        searchUsername();
        // eslint-disable-next-line
    }, [urlUsername]);

    // navigate
    const handleOnClick = () => {
        if (recruitUrl) {
            navigate(recruitUrl, { state: { from: storePaths(location) } });
        }
    };

    const searchUsername = () => {
        setData([]);
        setBreadcrumb([]);
        setCheckError(false);
        const newUsername = urlUsername ? urlUsername : '';
        setLoading(true);
        getUrl(`genealogy/placement`, { username: newUsername }).then(response => {
            let { status, message, error, data } = response;
            if (status) {
                setData(data.tree_data);
                setBreadcrumb(data.breadcrumb);
                setState({ ...state, username: newUsername });
                setFarLeftRight(data.most_left_right);

                if (data.tree_data.children[0].username === "" && data.tree_data.children[1].username !== "") {
                    // default show right if no left
                    setTreePosition({ 3: 2 });
                }
            } else {
                if (error || message) {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    setCheckError(true);
                    addAlert('', message, 'error', '');
                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setCheckError(true);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    // SEARCH
    const handleSearchOpen = () => {
        setOpenSearch(!openSearch);
    }
    const handleSearch = (value) => {
        setBreadcrumb([]);
        navigate(`/community/placement?u=${value}`, { state: { from: storePaths(location) } });
    }

    // QR CODE
    const showQR = (placement_username, position) => {
        setQrUrl(`${window.location.origin}/register?r=${btoa(username)}&placement=${btoa(placement_username)}&position=${position}`);
        setRecruitUrl(`/account/recruit?r=${btoa(username)}&placement=${btoa(placement_username)}&position=${position}`);
        setShowQr(true);
        setQrData({ username: placement_username, position: position });
    };
    const copyLink = () => {
        navigator.clipboard.writeText(qrUrl);
        addAlert('', t('general.copied'), 'success', '');
    };

    // TREE DATA
    const getMaxRankDisplay = (data) => {
        let name = { en: 'T0', cn: 'T0' };
        if ((data.rank_code && !data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code >= data.crank_code))) {
            name = JSON.parse(data.rank_name);
        }
        if ((!data.rank_code && data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code < data.crank_code))) {
            name = JSON.parse(data.crank_name);
        }
        return name[i18n.language] ? name[i18n.language] : name.en;
    }
    const buildTree = (treeData, treePosition) => {
        let html = [];
        if (_.size(treeData)) {
            const placement_group_sales1 = _.size(treeData.placement_group_sales1) ? Math.trunc(treeData.placement_group_sales1) : "0";
            const placement_group_sales2 = _.size(treeData.placement_group_sales2) ? Math.trunc(treeData.placement_group_sales2) : "0";
            const carried_forward1 = _.size(treeData.vol1_aft) ? Math.trunc(treeData.vol1_aft) : "0";
            const carried_forward2 = _.size(treeData.vol2_aft) ? Math.trunc(treeData.vol2_aft) : "0";
            const today1 = _.size(treeData.vol1) ? Math.trunc(treeData.vol1) : "0";
            const today2 = _.size(treeData.vol2) ? Math.trunc(treeData.vol2) : "0";
            
            let uplineUsername = null;
            if(_.size(breadcrumb) > 0){
                uplineUsername = breadcrumb[breadcrumb.indexOf(treeData.username) - 1];
            }

            html.push(
                <div key="main-user-container" style={{ width: '100%', display: mobileView ? 'inline' : 'flex', justifyContent: 'center' }}>
                    <div className='main-user pos-relative' key="main-user" style={{ overflow:'visible', width: mobileView ? '100%' : '65%' }} sx={{ md: {width: '100%'}, lg: {width: '80%'} }}>
                        {
                            !isNil(uplineUsername) &&
                            <div className='flex-c-m w-full' onClick={() => handleSearch(uplineUsername)}>
                                <div className={`flex-c-m upline-button pos-absolute`} style={{ top: -13 }}>
                                    <FiChevronsUp />
                                </div>
                            </div>
                        }
                        <div className='flex-m'>
                            <div className='outer-white-circle-design'>
                                <div className='inner-white-circle-design'>
                                    <p className='white-circle-text-main'>{getMaxRankDisplay(treeData)}</p>
                                </div>
                            </div>
                            <div className='p-l-15 lh-12'>
                                <p className='fs-18'><b>{treeData.username}</b></p>
                                <p className='p-t-3 fs-15'>{t('genealogy.main')}</p>
                            </div>
                        </div>
                        <div className='p-t-15 txt-ivoryblack'>
                            <div className='flex-sb-m'>
                                <div className='w-full'>
                                    <div className='flex-sb flex-m'>
                                        <p className='main-list'>{t('genealogy.accumulateGroupBV')}</p>
                                        <div className='flex-sa-m'>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(placement_group_sales1, 0)}</p>
                                            <p className='main-list-point txt-center' style={{ width: 10 }}>|</p>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(placement_group_sales2, 0)}</p>
                                        </div>
                                    </div>
                                    <div className='flex-sb flex-m'>
                                        <p className='main-list'>{t('genealogy.carriedForward')}</p>
                                        <div className='flex-sa-m'>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(carried_forward1, 0)}</p>
                                            <p className='main-list-point txt-center' style={{ width: 10 }}>|</p>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(carried_forward2, 0)}</p>
                                        </div>
                                    </div>
                                    <div className='flex-sb flex-m'>
                                        <p className='main-list'>{t('genealogy.todayGroupBV')}</p>
                                        <div className='flex-sa-m'>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(today1, 0)}</p>
                                            <p className='main-list-point txt-center' style={{ width: 10 }}>|</p>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(today2, 0)}</p>
                                        </div>
                                    </div>
                                    <div className='flex-sb flex-m'>
                                        <p className='main-list'>{t('genealogy.totalDownlines')}</p>
                                        <div className='flex-sa-m'>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(treeData.total_left || 0, 0)}</p>
                                            <p className='main-list-point txt-center' style={{ width: 10 }}>|</p>
                                            <p className='main-list-point' style={{ width: 70 }}>{currencyFormat(treeData.total_right || 0, 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='w-full'>
                                    <p className='main-list'>{t('genealogy.accumulateGroupBV')}</p>
                                    <p className='main-list'>{t('genealogy.carriedForward')}</p>
                                    <p className='main-list'>{t('genealogy.todayGroupBV')}</p>
                                    <p className='main-list'>{t('genealogy.totalDownlines')}</p>
                                </div>
                                <div className='flex-sa-m'>
                                    <div style={{ width: 70 }}>
                                        <p className='main-list-point'>{placement_group_sales1}</p>
                                        <p className='main-list-point'>{carried_forward1}</p>
                                        <p className='main-list-point'>{today1}</p>
                                        <p className='main-list-point'>{treeData.total_left || 0}</p>
                                    </div>
                                    <div className='txt-center' style={{ width: 10 }}>
                                        <p className='main-list-point'>|</p>
                                        <p className='main-list-point'>|</p>
                                        <p className='main-list-point'>|</p>
                                        <p className='main-list-point'>|</p>
                                    </div>
                                    <div style={{ width: 70 }}>
                                        <p className='main-list-point'>{placement_group_sales2}</p>
                                        <p className='main-list-point'>{carried_forward2}</p>
                                        <p className='main-list-point'>{today2}</p>
                                        <p className='main-list-point'>{treeData.total_right || 0}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='bg-blur-brown pos-absolute'></div>
                    </div>
                </div>
            )
            if (_.size(treeData.children)) {
                html.push(
                    <React.Fragment key="main-user-sub">
                        {/* ///////////////  sub-user-line  /////////////// */}
                        <div className='flex-c'>
                            <div className='chart-border-color' style={{ borderRight: '1px dashed', height: 23 }} />
                        </div>
                        <div className='flex-c'>
                            <div className='chart-border-color' style={{ border: '1px dashed', borderBottom: 'none', borderRadius: '5px 5px 0 0', width: '50%', height: 23 }} />
                        </div>
                    </React.Fragment>
                )
                html.push(buildTreeChild(treeData.children, 2, treePosition[3]));
            }
        }
        
        return html;
    }
    const buildTreeChild = (treeData, level = 2, displayPosition = 1) => {
        let html = [];
        let displayPositionBkg = 'left';
        let shadow = 'out';
        let nextIndex = displayPosition === 2 ? 1 : 0;
        if (treePosition[level + 1] > 0) {
            nextIndex = treePosition[level + 1] === 2 ? 1 : 0;
        }

        if (_.size(treeData)) {
            html.push(
                <div className='flex-sb flex-t' key={`tree${level}`}>
                    {_.map(treeData, (_data) => {
                        if (level === 2) {
                            displayPositionBkg = _data.position === 1 ? "left" : "right";
                        } else {
                            displayPositionBkg = displayPosition === 1 ? "left" : "right";
                        }
                        shadow = _data.position === displayPosition ? "in" : "out";
                        if (level === 3) shadow = "in";
                        const placement_group_sales1 = _.size(_data.placement_group_sales1) ? Math.trunc(_data.placement_group_sales1) : "0";
                        const placement_group_sales2 = _.size(_data.placement_group_sales2) ? Math.trunc(_data.placement_group_sales2) : "0";
                        const carried_forward1 = _.size(_data.vol1_aft) ? Math.trunc(_data.vol1_aft) : "0";
                        const carried_forward2 = _.size(_data.vol2_aft) ? Math.trunc(_data.vol2_aft) : "0";
                        const today1 = _.size(_data.vol1) ? Math.trunc(_data.vol1) : "0";
                        const today2 = _.size(_data.vol2) ? Math.trunc(_data.vol2) : "0";
                        let cursor = "pointer";
                        // let cursor = (_data.username === "" || (level === 2 && _data.position != displayPosition)) ? "pointer" : "default";
                        const handleClick = () => {
                            if (_data.username === "") {
                                showQR(_data.placement_username, _data.position);
                            } else if (level === 2) {
                                setTreePosition({ ...treePosition, [level + 1]: _data.position });
                                handleSearch(_data?.username)
                            }
                        }
                        return (
                            <div className={`sub-user-${_data.position === 1 ? "left" : "right"}`} key={_data.id}>
                                {/* <div className={`sub-${displayPositionBkg}-bkg`} style={{ cursor }} onClick={ _data.username ? handleClick : null}> */}
                                <div className={`sub-${displayPositionBkg}-bkg genealogy-shadow-${shadow}`} style={{ cursor }} onClick={handleClick}>
                                    {_data.username === "" ?
                                        <div className='flex-sb-m'>
                                            {/* <div className='p-r-10 '>
                                                <p>{t('user.noUser')}</p>
                                            </div> */}
                                            <div className='p-r-10 '>
                                                <p className='fs-18'><b>{t('button.addNew')}</b></p>
                                                <p className='fs-14 p-t-3'>{_data.position === 1 ? t('genealogy.left') : t('genealogy.right')}</p>
                                            </div>
                                            <div className={`p-all-10 flex-c-m sub-${displayPositionBkg}-border`} style={{  height: xsView?40:60, borderRadius: 8, aspectRatio: '1/1' }}>
                                                <p className='fs-50'>+</p>
                                            </div>
                                            
                                            {/* <div className='p-r-10 '>
                                                <p className='fs-18'><b>{t('button.addNew')}</b></p>
                                                <p className='fs-14 p-t-3'>{_data.position === 1 ? t('genealogy.left') : t('genealogy.right')}</p>
                                            </div>
                                            <div className=' p-all-10 flex-c-m' style={{ border: '1px dashed #fff', height: xsView?40:60, borderRadius: 8, aspectRatio: '1/1' }}>
                                                <p className='fs-50'>+</p>
                                            </div> */}
                                        </div>
                                        :
                                        <>
                                            <div className={`${!xsView?'flex-m':''}`}>
                                                <div className={`sub-${displayPositionBkg}-outer-white-circle-design`}>
                                                    <div className={`sub-${displayPositionBkg}-inner-white-circle-design`}>
                                                        <p className={`white-circle-text-${displayPositionBkg}`}>{getMaxRankDisplay(_data)}</p>
                                                    </div>
                                                </div>
                                                <div className='p-l-10 lh-12' style={{ wordBreak: 'break-all' }}>
                                                    <p className='fs-17'><b>{_data.username}</b></p>
                                                    <p className='p-t-3 fs-14'>{_data.position == 1 ? t('genealogy.left') : t('genealogy.right')}</p>
                                                </div>
                                            </div>
                                            <div className={`${!xsView?'flex-sb-m':''} w-full p-t-15`}>
                                                <p className='sub-list'>{t('genealogy.accumulateGroupBV')}</p>
                                                <p className='sub-list-point'>{placement_group_sales1} - {t('genealogy.leftShort')}<br />{placement_group_sales2} - {t('genealogy.rightShort')}</p>
                                            </div>
                                            <div className={`${!xsView?'flex-sb-m':''} w-full`}>
                                                <p className='sub-list'>{t('genealogy.carriedForward')}</p>
                                                <p className='sub-list-point'>{carried_forward1} - {t('genealogy.leftShort')}<br />{carried_forward2} - {t('genealogy.rightShort')}</p>
                                            </div>
                                            <div className={`${!xsView?'flex-sb-m':''} w-full`}>
                                                <p className='sub-list'>{t('genealogy.totalDownlines2')}</p>
                                                <p className='sub-list-point'>{_data.total_left || 0} - {t('genealogy.leftShort')}<br />{_data.total_right || 0} - {t('genealogy.rightShort')}</p>
                                            </div>
                                        </>
                                    }
                                </div>
                                {level === 2 && !isEmpty(_data?.username)  &&
                                    <div className='flex-c-m w-full' style={{ marginTop: -15 }} onClick={() => handleSearch(_data.username)}>
                                        <div className={`flex-c-m downline-button-${displayPositionBkg}`}>
                                            <FiChevronsDown />
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>,
                // ((level == 2 && _.size(treeData?.[nextIndex]?.children)) > 0) ?
                //     <React.Fragment key={`sub${level}${displayPosition}`}>
                //         {/* ///////////////  sub-downline-line  /////////////// */}
                //         <div className={`flex-${nextIndex === 0 ? "l" : "r"}`} style={{ width: '50%', margin: '0 auto' }}>
                //             <div className='chart-border-color' style={{ borderRight: '1px dashed', height: 23 }} />
                //         </div>
                //         <div className='flex-c'>
                //             <div className='chart-border-color' style={{ border: '1px dashed', borderBottom: 'none', width: '50%', height: 23 }} />
                //         </div>
                //         {buildTreeChild(treeData[nextIndex]['children'], level + 1, treePosition[level + 1])}
                //     </React.Fragment>
                //     : null
            )

        }
        return html;
    }

    return (
        <div style={{ display: mobileView ? 'block' : 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* breadcrumb */}
            {(_.size(breadcrumb) > 0) && !_.includes(['', null, username], urlUsername) &&
                <div style={{ padding: 0, width: '100%' }}>
                    <FormControl fullWidth className='dis-flex flex-b'>
                        <Select
                            variant='standard'
                            value={urlUsername || ''}
                            MenuProps={{ classes: { paper: styles.listRoot } }}
                            onChange={(event) => handleSearch(event.target.value)}
                            className={`txt-upper txt-center ${styles.selectBox}`}
                            sx={{ '& .MuiSelect-select': { color:'#141418!important'} }}
                            // style={{ background: 'transparent' }}
                            disableUnderline
                            IconComponent={() => (
                                <FaChevronDown className={styles.dropdownIcon} />
                            )}
                        >
                            {/* <MenuItem key={''} value={''} className='txt-upper' style={{ color: theme.palette.primary.main, fontSize: 14 }}>{t('general.pleaseSelect')}</MenuItem> */}
                            {
                                _.map(breadcrumb, (detail, key) => (
                                    <MenuItem key={key} value={detail} className='txt-upper' style={{  fontSize: 14 }}>{detail}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            }

            {/* searchbar */}
            {/* {
                openSearch ?
                    <div className='p-t-15'>
                        <TextField
                            variant='standard'
                            InputLabelProps={{ shrink: true }}
                            name="search"
                            placeholder={t('genealogy.username')}
                            fullWidth
                            value={state?.username || ''}
                            onChange={(event) => setState({ ...state, username: event.target.value })}
                            onKeyDown={(e) => (e.keyCode == 13 && handleSearch(state.username))}
                            sx={{ '& .MuiInputBase-root': { height: 47, borderRadius: 49 } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleSearch(state.username)}
                                            edge="end"
                                        >
                                            <BiSearch style={{ color: '#8B8B8B' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className='bor-48'
                            style={{ color: '#8B8B8B' }}
                        />
                    </div>
                    :
                    <div className='p-t-15 dis-flex flex-m flex-col-sb' style={{ flexDirection: 'row' }}>
                        <Typography style={{ color: '#8B8B8B' }}>{t('genealogy.totalActiveDownlines', { value: 0 })}</Typography>
                        <IconButton onClick={handleSearchOpen}>
                            <BiSearch className='fs-25' style={{ color: '#8B8B8B' }} />
                        </IconButton>
                    </div>
            } */}

            {/* tree */}
            {
                !checkError
                    ?
                    <div style={{ marginTop: 20, width: mobileView ? '100%' : '70%' }}>
                        {buildTree(data, treePosition)}
                        {
                            _.size(data) > 0
                                ?
                                <div className='flex-sb-m' style={{ marginTop: 30 }}>
                                    {farLeftRight[1] ? <div className={styles.bottomLeft} onClick={() => handleSearch(farLeftRight[1])}><FiCornerLeftDown style={{ verticalAlign: "middle" }} /> {t('genealogy.bottomLeft')}</div> : <div></div>}
                                    {farLeftRight[2] ? <div className={styles.bottomRight} onClick={() => handleSearch(farLeftRight[2])}>{t('genealogy.bottomRight')} <FiCornerRightDown style={{ verticalAlign: "middle" }} /></div> : <div></div>}
                                </div>
                                :
                                null
                        }
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                        <div style={{ width: 200 }}>
                            {/* <img src={'/images/genealogy/downline_none.png'} className='s-full' alt="none_genealogy" loading='lazy' /> */}
                            <img src={'/images/general/filter-search-empty-data.png'} className='s-full' alt="No data" loading='lazy' />
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: 20 }}>
                            <Typography variant='h6'><b>{t('genealogy.notFoundUsername')}</b></Typography>
                            <Typography>{t('genealogy.textErrorUsername')}</Typography>
                        </div>
                    </div>
            }

            {/* QR Dialog */}
            <Dialog
                open={showQr}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowQr(false)}
            >
                <div style={{ maxWidth: 350}} className='p-lr-35'>
                    <div className='p-t-35 p-b-15 flex-c-m text-gold'>
                        <Typography variant='h6' style={{ fontWeight: 'bold', lineHeight: 1.1}} >{t("title.referralQr")}</Typography>
                    </div>
                    <div>
                        {/* QRCode */}
                        <div className='p-b-20 flex-c '>
                            <div className='bgwhite p-all-25 bor-20' style={{ boxShadow: ' 3px 3px 2px 0px rgba(0, 0, 0, 0.10) inset' }}>
                                <QRCode
                                    size='178'
                                    value={qrUrl}
                                    renderAs="svg"
                                    fgColor={'black'}
                                />
                            </div>
                        </div>
                        {/* URlink */}
                        
                        <div className='p-b-20 txt-center'>
                            <p className='fs-18 m-t-10 txt-theme txt-upper'><b>{qrData.username} - {qrData.position === 1 ? t('genealogy.left') : t('genealogy.right')}</b></p>
                            <p className='txt-white fs-14' ref={shareLink} style={{wordBreak: 'break-word',}}>
                                {qrUrl}
                            </p>
                        </div>
                        {/* ShareButton */}
                        <Box style={{ marginBottom: 20 }}>
                            <Button
                                onClick={copyLink}
                                variant="contained"
                                className='big-button'
                                fullWidth
                            >
                                {t("button.copy")}
                            </Button>
                        </Box>
                    </div>
                    <div >
                        <Box style={{ marginBottom: 20 }}>
                            <Link underline='none' color="secondary" onClick={handleOnClick}>
                                <Button
                                    variant="contained"
                                    className='big-button'
                                    fullWidth
                                >
                                    {t("genealogy.registerForDownliine")}
                                </Button>
                            </Link>
                        </Box>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        // backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 20,
        color:'#222',
        background:'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #b8905b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5)',
        border: '1px solid transparent',
    },
    dropdownIcon: {
        pointerEvents: 'none',
        // position: 'absolute',
        // right: 0
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    bottomLeft: {
        borderRadius: '5px',
        padding: '5px 10px',
        // color: '#8c713b',
        // backgroundColor: '#d9c286',
        color: '#2f3133',
        background:'#f0f0f0',
        fontWeight: 'bold',
        boxShadow: 'inset 2px 2px 4px 0 #ffffff38, inset -2px -2px 3px 0 #0003, 4px 4px 4px 0 #00000052',
        cursor: 'pointer',
        fontSize: 13,
    },
    bottomRight: {
        borderRadius: '5px',
        padding: '5px 10px',
        // color: '#cecece;',
        // background: 'linear-gradient(148deg, #333333 0%, #282828 100%)',
        color: '#fff',
        background: '#5b5b5b',
        fontWeight: 'bold',
        boxShadow: 'inset 2px 2px 4px 0 #ffffff38, inset -2px -2px 3px 0 #0003, 4px 4px 4px 0 #00000052',
        cursor: 'pointer',
        fontSize: 13,
    }
}));