import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';

import { Grid, Typography, Box, Tabs, Tab, TextField, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import { BiSearch } from "react-icons/bi";
import GenealogySponsor from './GenealogySponsor';
import GenealogyPlacement from './GenealogyPlacement';
import "@css/tree.css";

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery, storePaths , currencyFormat } from '@utils/Tools';
// import { MuiThemeProvider } from '@material-ui/core';
import UserBasicInfoCard from '@layouts/Dashboard/UserBasicInfoCard';

// import theme from '../../Theme';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Genealogy(props) {
    const [state, setState] = useState({
        pageIndex: null,
        subPage: 'sponsor',
        totalDirectDownlines: 0,
        totalDownlines: 0,
        username: '',
    });
    const [user, setUser] = useState({});
    const [checkError, setCheckError] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();
    let { page } = useParams();
    let name = useQuery().get('u');
    const { username, profile_image, max_rank, email, mobile } = useSelector(state => state.user);
    const maxRankName = max_rank ? max_rank.name[i18n.language] : null;

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        if (page) {
            if (_.includes(['sponsor', 'placement'], page)) {
                setState({
                    ...state,   
                    pageIndex: page === 'placement' ? 1 : 0,
                    subPage: page,
                })
            }
        }
    }, [page]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        setCheckError(false);
        getUrl(`/sponsor-genealogy`, { username: username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setUser(data.user);
                    setOpenSearch(false);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        setCheckError(true);
                        addAlert('', message, 'error', '');
                    }
                }
                setLoading(false);
            }

        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                setCheckError(true);
                addAlert('', JSON.stringify(error.message), 'error', '');
            }
        });
        return () => { isMountedRef.current = false };
    }, [username]);

    const displayPage = () => {
        if (state.subPage == 'sponsor') {
            return <GenealogySponsor username={name} />
        }
        else if(state.subPage == 'placement'){
            return <GenealogyPlacement />
        }
    }

    const handleSearch = () => {
        setOpenSearch(!openSearch);
    }

    const handleChange = (event, newValue) => {
        setState({...state, username: ''});
        setOpenSearch(false);
        navigate(`/community/${newValue === 1 ? 'placement' : 'sponsor'}`, { state: { from: storePaths(location) } });
    };

    const viewMore = (value) => {
        setState({...state, username: ''});
        setOpenSearch(false);
        if (state.subPage == 'sponsor') {
            navigate(`/community/sponsor?u=${value}`, { state: { from: storePaths(location) } });
        }
        else if(state.subPage == 'placement'){
            navigate(`/community/placement?u=${value}`, { state: { from: storePaths(location) } });
        }
    }

    return (
        <Box style={{ paddingLeft: 15, paddingRight: 15, minHeight: '90vh', marginTop: 80, overflowY: 'hidden' }}>
            <Box className='p-lr-20 p-b-46 p-t-20' >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        {/* <div className='flex-m s-full p-tb-20 p-lr-20 m-b-30 pos-relative'>
                            <div>
                                {
                                    profile_image ?
                                        <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                        :
                                        <div className={styles.outerThemeCircleDesign}>
                                            <Avatar className={styles.avatarStyle}>
                                                <Typography style={{ color: '#f1f1f1' }} className='txt-upper' variant='h5'>{username ? username.substring(0, 1) : '-'}</Typography>
                                            </Avatar>
                                        </div>
                                }
                            </div>
                            <div className='fs-16 lh-12 p-l-25' style={{ color: theme.palette.gray.dtext }}>
                                <p className='fs-23' style={{ color: '#82512D', wordBreak: "break-all" }}><b>{username}</b></p>
                                <p>{maxRankName || t('user.guest')}</p>
                                <p style={{ wordBreak: "break-all" }}>{email}</p>
                                <p>{mobile}</p>
                            </div>
                            <div className='pos-absolute' style={{ right: 0, top: 0 }}>
                                <Link underline='none' to={`/account/recruit`} component={RouterLink}>
                                    <img src='/images/dashboard/qr.png' alt="qrcode" style={{ width: 70 }} />
                                </Link>
                            </div>
                        </div> */}
                        <UserBasicInfoCard isGenealogyPage={true} />
                    </Grid>
                    <Grid item xs={6} md={3} lg={4}>
                        <div className={`flex-c flex-col p-tb-20 p-lr-20 ${mobileView?'w-full':'s-full'} box-out pos-relative txt-center`} >
                            <Typography variant='h6' className='text-gold-short' style={{ wordBreak: 'break-word' }}><b>{ currencyFormat(user?.personal_sales ?? 0 , 2) }</b></Typography>
                            {/* <Typography variant='h6' style={{ wordBreak: 'break-word' }}><b>{user?.personal_sales ? Number(user?.personal_sales).toFixed(2) : (0).toFixed(2)}</b></Typography> */}
                            <Typography variant='body1'>{t('genealogy.personalStake')}</Typography>
                        </div>
                    </Grid>

                    <Grid item xs={6} md={3} lg={4}>
                        <div className={`flex-c flex-col p-tb-20 p-lr-20 ${mobileView?'w-full':'s-full'} box-out pos-relative txt-center`} >
                            <Typography variant='h6' className='text-gold-short' style={{ wordBreak: 'break-word' }}><b>{ currencyFormat(user?.group_sales ?? 0, 2) }</b></Typography>
                            {/* <Typography variant='h6' style={{ wordBreak: 'break-word' }}><b>{user?.group_sales ? Number(user?.group_sales).toFixed(2) : (0).toFixed(2)}</b></Typography> */}
                            <Typography variant='body1'>{t('genealogy.totalGroupStake')}</Typography>
                        </div>
                    </Grid>
                </Grid>

                {!_.isNull(state.pageIndex) ? 
                    <div className='p-t-40'>
                        {/* <Typography variant="h6" className="flex-c-m" style={{fontWeight: "bold"}}>{t('title.genealogy')}</Typography> */}
                        <Grid container spacing={{ md: 2 }} className='flex-m'>
                            <Grid item xs={12} md={6}>
                                <Tabs
                                    value={state.pageIndex}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    textColor='primary'
                                    indicatorColor='primary'
                                    className='bgblack'
                                    style={{ margin: "15px 0px" }}
                                >
                                    <Tab label={t('title.sponsor')} />
                                    <Tab label={t('title.placement')} />
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} md={6} className='flex-r'>
                                {
                                    openSearch ?
                                        <div style={{ width: mobileView ? '100%' : 'auto', marginBottom: mobileView ? 20 : 0 }}>
                                            <TextField
                                                variant='standard'
                                                InputLabelProps={{ shrink: true }}
                                                name="search"
                                                placeholder={t('genealogy.username')}
                                                fullWidth
                                                value={state?.username || ''}
                                                onChange={(event) => setState({ ...state, username: event.target.value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '')})}
                                                onKeyDown={(e) => (e.keyCode == 13 && viewMore(state.username))}
                                                sx={{ '& .MuiInputBase-root': { height: 41, borderRadius: 8 } }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => viewMore(state.username)}
                                                                edge="end"
                                                            >
                                                                <BiSearch />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                className='bor-8'
                                                style={{ color: '#8B8B8B'  }}
                                            />
                                        </div>
                                        :
                                        <div className='dis-flex flex-m flex-r' style={{ width: '100%', marginBottom: mobileView ? 20 : 0 }}>
                                            {
                                                state.pageIndex === 1 ? null
                                                    : <>
                                                        {mobileView ? <Typography style={{ color: '#8B8B8B', paddingRight: 20 }}>{t('genealogy.totalActiveDownlines', { value: user?.total_downline })}</Typography> : null}
                                                    </>
                                            }
                                            <div className='server-box gradient-gold-btn' style={{ maxWidth: 40, aspectRatio: 1 / 1, }}>
                                                <IconButton onClick={handleSearch}>
                                                    <BiSearch className='fs-25 txt-base' />
                                                </IconButton>
                                            </div>
                                        </div>
                                }
                            </Grid>
                        </Grid>

                        <div>
                            {/* /////////  Sponsor Genealogy /////////*/}
                            <TabPanel value={state.pageIndex} index={0} dir={theme.direction}>
                                <Grid item xs={12} className={styles.root} >
                                    {displayPage()}
                                </Grid>
                            </TabPanel>

                            {/* /////////  Placement Genealogy /////////*/}
                            <TabPanel value={state.pageIndex} index={1} dir={theme.direction}>
                                <Grid item xs={12} className={styles.root} >
                                    {displayPage()}
                                </Grid>
                            </TabPanel>
                        </div>
                    </div> : null}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    goldFrame: {
        boxShadow: 'rgb(0 0 0 / 32%) 0 0 5px 0',
        padding: 5,
        borderRadius: 15,
        background: '#fff',
    },
    frameBkg: {
        background: '#fff',
        padding: '10px 15px 15px'
    },
    outerThemeCircleDesign: {
        border: `1px solid ${theme.palette.primary.main}`, 
        borderRadius: 49, 
        width: 80, 
        height: 80, 
        aspectRatio: 1 / 1, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    avatarStyle: {
        height: 70,
        width: 70,
        flexShrink: 0,
        background: '#82512c'
    },
}));