import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { Grid, IconButton, TextField, FormControl, Select, MenuItem, OutlinedInput, Typography, InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BiSearch } from "react-icons/bi";
import { MdKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";

import { getUrl } from '@utils/ApiAction';
import { stringToLang, storePaths , currencyFormat  } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import theme from '../../Theme';
import moment from 'moment-timezone';

export default function GenealogySponsor(props) {
    const { t, i18n } = useTranslation();
    const { id, username } = useSelector(state => state.user);
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const styles = useStyles();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    let location = useLocation();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [tree, setTree] = useState([]);
    const [user, setUser] = useState({});
    const [uplines, setUplines] = useState({});
    const [openSearch, setOpenSearch] = useState(false);
    const [expandMore, setExpandMore] = useState({});
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '', search: 0 });
    const [expanded, setExpanded] = useState([]);
    const [checkError, setCheckError] = useState(false);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        setState({ ...state, username: props.username || "", search: state.search + 1 });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, props.username]);

    useEffect(() => {
        if (state.search > 0) {
            searchUsername()
        }
        // eslint-disable-next-line
    }, [state.search]);

    const searchUsername = () => {
        setTree([]);
        setLoading(true);
        setCheckError(false);
        getUrl(`/sponsor-genealogy`, { username: state.username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setTree(data.tree_data);
                    setUser(data.user);
                    setOpenSearch(false);
                    setExpanded([]);
                    
                    let expandStateArr = {};
                    if (props.username) {
                        expandStateArr[data.tree_data.id] = {id : data.tree_data.id, expand: false};
                        setExpanded([`${data.tree_data.id}`]);
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    } else {
                        _.map(data.tree_data.children, node => (
                            expandStateArr[node.id] = { id: node.id, expand: false }
                        ))
                    }
                    setExpandMore(expandStateArr);
                    setUplines(data.uplines);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        setCheckError(true);
                        addAlert('', message, 'error', '');
                    }
                }
                setLoading(false);
            }

        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                setCheckError(true);
                addAlert('', JSON.stringify(error.message), 'error', '');
            }
        });
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSearch = () => {
        setOpenSearch(!openSearch);
    }

    const handleExpand = (id) => {
        setExpandMore({ ...expandMore, [id]: { ...expandMore.id, expand: !expandMore[id].expand} });
    }

    const viewMore = (value) => {
        navigate(`/community/sponsor?u=${value}`, { state: { from: storePaths(location) } });
    }

    const TreeComponent = (data) => (
        <Grid container spacing={2} className='p-t-15 p-b-10 p-l-10'>
            {/* <Grid item xs={4}>
                <Typography variant='body2' className='txt-upper' style={{ color: data.type === 'main' ? '#fff' : '#8B8B8B' }}><b>{t('genealogy.totalStakes')}</b></Typography>
            </Grid> */}
            <Grid item xs={6} className='dis-flex flex-m' style={{ flexDirection: 'column' }}>
                <p className={`${data.type === 'main' ? 'txt-base' : 'text-gold-short'} fs-14`} style={{ wordBreak: 'break-word' }}><b>{ currencyFormat(data.info?.personal_sales ?? 0, 2) }</b></p>
                {/* <Typography variant='body2' style={{ wordBreak: 'break-word', color: data.type === 'main' ? '#fff' : theme.palette.darkBase.main }}><b>{data.info?.personal_sales ? Number(data.info?.personal_sales).toFixed(2) : (0).toFixed(2)}</b></Typography> */}
                <p className={`${data.type === 'main' ? 'txt-base' : 'txt-white'} fs-14`}><b>{t('genealogy.personalStake')}</b></p>
            </Grid>
            <Grid item xs={6} className='dis-flex flex-m' style={{ flexDirection: 'column' }}>
                <p className={`${data.type === 'main' ? 'txt-base' : 'text-gold-short'} fs-14`} style={{ wordBreak: 'break-word' }}><b>{ currencyFormat(data.info?.sponsor_group_sales ?? 0, 2) }</b></p>
                {/* <Typography variant='body2' style={{ wordBreak: 'break-word', color: data.type === 'main' ? '#fff' : theme.palette.darkBase.main }}><b>{data.info?.sponsor_group_sales ? Number(data.info?.sponsor_group_sales).toFixed(2) : (0).toFixed(2)}</b></Typography> */}
                <p className={`${data.type === 'main' ? 'txt-base' : 'txt-white'} fs-14`}><b>{t('genealogy.totalGroupStake')}</b></p>
            </Grid>
        </Grid>
    )

    const getMaxRankDisplay = (data) => {
        let name = { en: 'T0', cn: 'T0' };
        if ((data.rank_code && !data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code >= data.crank_code))) {
            name = JSON.parse(data.rank_name);
        }
        if ((!data.rank_code && data.crank_code) || ((data.rank_code && data.crank_code) && (data.rank_code < data.crank_code))) {
            name = JSON.parse(data.crank_name);
        }
        return name[i18n.language] ? name[i18n.language] : name.en;
    }

    const renderTree = (nodes) => {
        let main = nodes.id === tree.id ? true : false;
        let notMainHasChild = !main && _.size(nodes.children) > 0;
        return (
            <TreeItem key={nodes.id} nodeId={`${nodes.id}`}
                className={main ? `${styles.downlineBoxDesign2} gradient-gold-bg` : `${styles.downlineBoxDesign} gradient-gold-border`}
                onClick={notMainHasChild ? (mobileView ? () => handleExpand(nodes.id) : () => viewMore(nodes.username)) : null}
                disabled={mobileView && notMainHasChild ? false : true}
                label={
                    <div style={{ cursor: notMainHasChild ? 'pointer' : 'auto' }}>
                        <div className='dis-flex flex-sb'>
                            <div className='dis-flex flex-m'>
                                <div className={main ? styles.outerThemeCircleDesign : styles.outerWhiteCircleDesign}>
                                    <div className={main ? styles.innerThemeCircleDesign : styles.innerWhiteCircleDesign}>
                                        <p variant='body1' className={main ? 'txt-white' : 'txt-base'}><b>{getMaxRankDisplay(nodes)}</b></p>
                                    </div>
                                </div>
                                <div className='p-l-10 dis-flex flex-col flex-c'>
                                    <p className={`${main ? 'txt-base' : 'text-gold-short'} txt-truncate txt-upper` }><b>{nodes.username}</b></p>
                                    <p className={`${main ? 'txt-base' : 'txt-white'} fs-14`}>{t('genealogy.totalDownlinesCount', { value: nodes.total_downline })}</p>
                                </div>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                {
                                    mobileView && notMainHasChild
                                        ?
                                        expandMore?.[nodes.id]?.expand ?
                                            <IconButton disabled>
                                                <MdOutlineKeyboardDoubleArrowUp className={styles.expandUpDesign} />
                                            </IconButton>
                                            : <IconButton disabled>
                                                <MdKeyboardDoubleArrowDown className={`${styles.expandDownDesign} gradient-gold-btn txt-base`} />
                                            </IconButton>
                                        : null
                                }
                                {
                                    !mobileView && notMainHasChild ?
                                        <IconButton onClick={() => viewMore(nodes.username)}>
                                            <MdKeyboardDoubleArrowDown className={`${styles.expandDownDesign} gradient-gold-btn txt-base`} />
                                        </IconButton>
                                        : null
                                }
                            </div>
                        </div>
                        <TreeComponent type={main ? 'main' : 'submain'} info={nodes} />
                        {
                            (!expandMore?.[nodes.id]?.expand || !mobileView) && notMainHasChild &&
                            <div className='pos-absolute p-all-15 bor-15' style={{ zIndex: -1, bottom: -21, right: 0, width: '100%', height: 50, background: 'transparent', border: '1px solid #f7c64e'}} />
                        }
                    </div>
                }
            >
                {mobileView && !main && Array.isArray(nodes.children) ? nodes.children.map((node) => renderSubTree(node)) : null}
            </TreeItem>
        )
    };

    const renderSubTree = (nodes) => (
        <div key={nodes.id}>
            <div className="divider-list-black" />

            <div className='p-t-10 p-b-10'>
                <div className='dis-flex flex-m flex-sb'>
                    <div className='p-l-10 dis-flex flex-col flex-c'>
                        <Typography variant='body2' className='txt-truncate txt-upper' style={{ color: theme.palette.darkBase.main }}><b>{nodes.username} {'[' + getMaxRankDisplay(nodes) + ']'}</b></Typography>
                        <Typography variant='body2'>{t('genealogy.totalDownlinesCount', {value: nodes.total_downline})}</Typography>
                    </div>
                    <div style={{ textAlign: 'end' }}>
                        {
                            nodes.total_downline > 0 ?
                                <IconButton onClick={() => viewMore(nodes.username)}>
                                    <MdKeyboardDoubleArrowDown className={styles.expandDownDesign} />
                                </IconButton>
                                : null
                        }
                    </div>
                </div>

                <TreeComponent type={'submain'} info={nodes}/>
            </div>
        </div>
    )

    return (
        <div>
            {/* <div className={styles.mainBoxDesign}>
                <div className='dis-flex flex-m'>
                    <div className={styles.outerWhiteCircleDesign}>
                        <div className={styles.innerWhiteCircleDesign}>
                            <Typography variant='body1' className='txt-center' style={{ color: "#B17435" }}><b>{(user?.max_rank?(user?.max_rank?.name?.[i18n.language]?user?.max_rank?.name?.[i18n.language]:user?.max_rank?.name?.en):'T0').substring(0, 2)}</b></Typography>
                        </div>
                    </div>
                    <div className='p-l-10 dis-flex flex-col flex-c'>
                        <Typography variant='body2' className='txt-truncate txt-upper' style={{ color: '#fff' }}><b>{username}</b></Typography>
                        <Typography variant='body2' style={{ color: '#fff' }}>{t('genealogy.totalDownlinesCount', {value: user?.total_downline})}</Typography>
                    </div>
                </div>

                <TreeComponent type={'main'} info={user}/>
            </div> */}

            {/* <div>
                <Typography variant="caption" style={{ marginLeft: 10 }}>{t('genealogy.salesUpdated') + ": " + moment().startOf('day').format("YYYY-MM-DD LT")}</Typography>
            </div> */}

            {/* <div>
                {
                    openSearch ?
                        <div className='p-t-15'>
                            <TextField
                                variant='standard'
                                InputLabelProps={{ shrink: true }}
                                name="search"
                                placeholder={t('genealogy.username')}
                                fullWidth
                                value={state?.username || ''}
                                onChange={(event) => setState({ ...state, username: event.target.value })}
                                onKeyDown={(e) => (e.keyCode == 13 && viewMore(state.username))}
                                sx={{ '& .MuiInputBase-root': {height: 47, borderRadius: 49 } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => viewMore(state.username)}
                                                edge="end"
                                            >
                                                <BiSearch style={{ color: '#8B8B8B' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                className='bor-48'
                                style={{ color: '#8B8B8B' }}
                            />
                        </div>
                        :
                        <div className='p-t-15 dis-flex flex-m flex-col-sb' style={{ flexDirection: 'row' }}>
                            <Typography style={{ color: '#8B8B8B' }}>{t('genealogy.totalActiveDownlines', {value: user?.total_downline})}</Typography>
                            <IconButton onClick={handleSearch}>
                                <BiSearch className='fs-25' style={{ color: '#8B8B8B' }} />
                            </IconButton>
                        </div>
                }
            </div> */}
            {
                mobileView ? null : <div className='p-tb-10'>
                    <Typography className='text-gold'>{t('genealogy.totalActiveDownlines', { value: user?.total_downline })}</Typography>
                </div>
            }
            {
                user?.total_downline > 0  ?
                    <>
                        {
                            props.username && tree.id !== id &&
                            <div style={{ padding: 0 }}>
                                <FormControl fullWidth className='dis-flex flex-b'>
                                    <Select
                                        variant='standard'
                                        value={props?.username || ''}
                                        MenuProps={{ classes: { paper: styles.listRoot } }}
                                        onChange={(event) => viewMore(event.target.value)}
                                        className={`txt-upper txt-center ${styles.selectBox}`}
                                        disableUnderline
                                        sx={{ '& .MuiSelect-select': { color:'#141418!important'} }}
                                        IconComponent={() => (
                                            <FaChevronDown className={styles.dropdownIcon} />
                                        )}
                                    >
                                        {/* <MenuItem key={''} value={''} className='txt-upper' style={{ color: theme.palette.primary.main, fontSize: 14 }}>{t('general.pleaseSelect')}</MenuItem> */}
                                        {
                                            _.map(uplines, (detail, key) => (
                                                <MenuItem key={key} value={detail} className='txt-upper fs-14' >{detail}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }

                        <TreeView
                            className={styles.tree}
                            defaultExpanded={[props.username ? `${tree?.id}` : `${id}`]}
                            expanded={expanded}
                            onNodeToggle={handleToggle}
                            style={{ position: 'relative', paddingTop: 15, zIndex: 1 }}
                        >
                            <Grid container columnSpacing={{ sm: 4, md: 4 }}>
                                {
                                    props.username && tree.id !== id
                                        ?
                                        <>
                                            <Grid item xs={12} md={6} lg={4}>{renderTree(tree)}</Grid>
                                        </>
                                        :
                                        null
                                }
                                {
                                    _.size(tree.children) > 0
                                        ?
                                        _.map(tree.children, (node, idx) => <Grid item key={idx} xs={12} md={6} lg={4}>{renderTree(node)}</Grid>)
                                        :
                                        null
                                }
                            </Grid>
                        </TreeView>
                    </>
                    :
                    loading ?
                        null
                        :
                        <div className='flex-c-m flex-col' style={{height: 400 }}>
                            <div style={{ width: 200 }}>
                                {/* <img src={'/images/genealogy/downline_none.png'} className='s-full' alt="none_genealogy" loading='lazy' /> */}
                                <img src={'/images/general/filter-search-empty-data.png'} className='s-full' alt="No data" loading='lazy' />
                            </div>
                            <div className='p-t-20 txt-empty'>
                                <p><b>{checkError ? t('genealogy.notFoundUsername') : t('genealogy.noDownline')}</b></p>
                                <p>{checkError ? t('genealogy.textErrorUsername') : t('genealogy.textNoDownline')}</p>
                            </div>
                        </div>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    mainBoxDesign: {
        padding: 25, 
        borderRadius: 15, 
        background: 'url("/images/genealogy/genealogy_bg.png"), linear-gradient(109deg, #B17435 3.41%, #DEA96C 98.09%)', 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        filter: 'drop-shadow(3px 3px 6px #FFF)',
    },
    outerWhiteCircleDesign: {
        border: '1px solid #eed189', 
        borderRadius: 49, 
        width: 50, 
        height: 50, 
        aspectRatio: 1 / 1, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    innerWhiteCircleDesign: {
        // background: '#fff', 
        background:'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #b8905b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5)',
        borderRadius: 49, 
        padding: 10, 
        width: 42, 
        height: 42, 
        aspectRatio: 1 / 1
    },
    outerThemeCircleDesign: {
        border: `1px solid ${theme.palette.darkBase.main}`, 
        borderRadius: 49, 
        width: 50, 
        height: 50, 
        aspectRatio: 1 / 1, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    innerThemeCircleDesign: {
        background: theme.palette.darkBase.main, 
        borderRadius: 49, 
        padding: 10, 
        width: 42, 
        height: 42, 
        aspectRatio: 1 / 1
    },
    expandDownDesign: {
        // color: '#F0F0F0', 
        borderRadius: 5, 
        // background: theme.palette.primary.main, 
        // filter: 'drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.99))',
    },
    expandUpDesign: {
        color: theme.palette.primary.main, 
        borderRadius: 5, 
        border: `1px solid ${theme.palette.primary.main}`, 
        // filter: 'drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.99))'
    },
    downlineBoxDesign: {
        position: 'relative', 
        marginBottom: 25, 
        padding: '15px 15px 15px 0px', 
        borderRadius: 15, 
        // background: '#eee', 
    },
    downlineBoxDesign2: {
        position: 'relative', 
        marginBottom: 25, 
        padding: '15px 15px 15px 0px', 
        borderRadius: 15, 
        // background: theme.palette.primary.main, 
    },
    dividerDesign: {
        margin: '5px 0px', 
        borderRadius: 49, 
        height: 3, 
        background: '#F1F1F1', 
    },
    outlinedInput: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
    },
    notchedOutline: {
        border: 0,
        borderColor: 'transparent !important'
    },
    listRoot: {
        backgroundColor: '#fff',
    },
    selectBox: {
        // backgroundColor: theme.palette.primary.main,
        // color:'white',
        fontWeight: 700,
        fontSize: 14,
        height: 40,
        boxShadow: 'none',
        padding: 20,
        color:'#222',
        background:'linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #b8905b, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5)',
        border: '1px solid transparent',
    },
    dropdownIcon: {
        pointerEvents: 'none',
        fontSize:18,
        // position: 'absolute',
        // right: 0
    },
}));
